define("ember-tui-editor/components/tui-editor", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/debug", "@glimmer/component", "@glimmer/tracking", "ember-tui-editor/utils/load-locale-file"], function (_exports, _component, _templateFactory, _object, _debug, _component2, _tracking, _loadLocaleFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert this.setupEditor}}
    {{will-destroy this.teardownEditor}}
    ...attributes
  >
    {{yield}}
  </div>
  
  {{#if this.editor}}
    {{#each this.tuiOptionsDescriptors as |desc|}}
      {{did-update (fn this.updateOption desc) (get this.args (get desc 0))}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "/QQaoiNt",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"setupEditor\"]]],null],[4,[38,1],[[30,0,[\"teardownEditor\"]]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"editor\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"tuiOptionsDescriptors\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,6],[[28,[37,7],[[30,0,[\"updateOption\"]],[30,2]],null],[28,[37,8],[[30,0,[\"args\"]],[28,[37,8],[[30,2],0],null]],null]],null]],[1,\"\\n\"]],[2]],null]],[]],null]],[\"&attrs\",\"desc\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\",\"if\",\"each\",\"-track-array\",\"did-update\",\"fn\",\"get\"]]",
    "moduleName": "ember-tui-editor/components/tui-editor.hbs",
    "isStrictMode": false
  });
  let TuiEditor = (_class = class TuiEditor extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "editor", _descriptor, this);
      _defineProperty(this, "tuiOptions", ['previewStyle:changePreviewStyle', 'editType:changeMode:initialEditType', 'height:setHeight', 'minHeight:setMinHeight', 'language', 'useCommandShortcut', 'usageStatistics', 'toolbarItems', 'hideModeSwitch', 'viewer', 'value:setMarkdown:initialValue', 'hooks', 'plugins', 'previewHighlight', 'extendedAutolinks', 'customConvertor', 'placeholder:setPlaceholder', 'linkAttributes', 'customHTMLRenderer', 'customMarkdownRenderer', 'referenceDefinition', 'customHTMLSanitizer', 'frontMatter', 'widgetRules', 'theme']);
    }
    // splits the options that have a tuiMethod to be used on the template
    // with the {{did-update}} helper
    get tuiOptionsDescriptors() {
      return this.tuiOptions.map(d => d.split(':')).filter(_ref2 => {
        let [, tuiMethod] = _ref2;
        return !!tuiMethod;
      });
    }

    // gathers all the options to initialize TUI editor, respecting tuiOptions syntax
    get options() {
      let options = {};
      for (let o of this.tuiOptions) {
        let [optionName,, tuiOption] = o.split(':');
        tuiOption = tuiOption ? tuiOption : optionName;
        let value = this.args[optionName];
        if (value !== undefined) {
          options[tuiOption] = value;
        }
      }
      return options;
    }
    async setupEditor(element) {
      var _this = this;
      await (0, _loadLocaleFile.default)(this.options.language);
      const {
        Editor
      } = await emberAutoImportDynamic("@toast-ui/editor");
      this.editor = new Editor.factory(Object.assign(this.options, {
        el: element,
        events: {
          load: function () {
            for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
              args[_key] = arguments[_key];
            }
            return _this.eventInvoked('onLoad', ...args);
          },
          change: function () {
            for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
              args[_key2] = arguments[_key2];
            }
            return _this.eventInvoked('onChange', _this.editor?.getMarkdown(), ...args);
          },
          caretChange: function () {
            for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
              args[_key3] = arguments[_key3];
            }
            return _this.eventInvoked('onCaretChange', ...args);
          },
          focus: function () {
            for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
              args[_key4] = arguments[_key4];
            }
            return _this.eventInvoked('onFocus', ...args);
          },
          blur: function () {
            for (var _len5 = arguments.length, args = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
              args[_key5] = arguments[_key5];
            }
            return _this.eventInvoked('onBlur', ...args);
          }
        }
      }));
      this.eventInvoked('onInit', this.editor);
    }
    teardownEditor() {
      this.editor?.destroy();
    }

    // tests if an `actionName` function exists and calls it with the arguments if so
    eventInvoked(actionName) {
      for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
        args[_key6 - 1] = arguments[_key6];
      }
      this.args[actionName]?.(...args);
    }
    updateOption(_ref3, _ref4) {
      let [optionName, tuiMethod] = _ref3;
      let [value] = _ref4;
      // `value` is a special case because using `setValue`
      // moves the current cursor position so we need to avoid calling it
      // only call it when the editor value is different from the new value we got
      if (optionName === 'value' && this.editor.getMarkdown) {
        let editorValue = this.editor.getMarkdown();
        if (editorValue !== value) {
          this.editor.setMarkdown(value, false);
        }
      } else {
        (false && !(!!this.editor[tuiMethod]) && (0, _debug.assert)(`Editor instance should be have a function '${tuiMethod}' but found ${this.editor[tuiMethod]} instead.`, !!this.editor[tuiMethod]));
        this.editor[tuiMethod].call(this.editor, value);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "editor", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardownEditor", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teardownEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateOption"), _class.prototype)), _class);
  _exports.default = TuiEditor;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TuiEditor);
});