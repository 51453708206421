define("ember-basic-dropdown/modifiers/basic-dropdown-trigger", ["exports", "ember-modifier", "@ember/debug", "@ember/object", "@ember/destroyable", "ember-basic-dropdown/utils/has-moved"], function (_exports, _emberModifier, _debug, _object, _destroyable, _hasMoved) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let DropdownTriggerModifier = (_class = class DropdownTriggerModifier extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      _defineProperty(this, "didSetup", false);
      _defineProperty(this, "triggerElement", void 0);
      _defineProperty(this, "toggleIsBeingHandledByTouchEvents", false);
      _defineProperty(this, "touchMoveEvent", void 0);
      _defineProperty(this, "dropdown", void 0);
      _defineProperty(this, "desiredEventType", void 0);
      _defineProperty(this, "stopPropagation", void 0);
      (0, _destroyable.registerDestructor)(this, cleanup);
    }
    modify(element, positional, named) {
      (false && !(named.dropdown) && (0, _debug.assert)('must be provided dropdown object', named.dropdown));
      this.dropdown = named.dropdown;
      this.desiredEventType = named.eventType ?? 'click';
      this.stopPropagation = named.stopPropagation;
      if (!this.didSetup) {
        this.setup(element);
        this.didSetup = true;
      }
      this.update(element, positional, named);
    }
    setup(element) {
      // Keep a reference to the element for cleanup
      this.triggerElement = element;
      if (!element.getAttribute('role')) element.setAttribute('role', 'button');
      element.addEventListener('click', this.handleMouseEvent);
      element.addEventListener('mousedown', this.handleMouseEvent);
      element.addEventListener('keydown', this.handleKeyDown);
      element.addEventListener('touchstart', this.handleTouchStart);
      element.addEventListener('touchend', this.handleTouchEnd);
    }
    update(element, _positional, named) {
      const {
        dropdown
      } = named;
      element.setAttribute('data-ebd-id', `${dropdown.uniqueId}-trigger`);
      element.setAttribute('aria-owns', `ember-basic-dropdown-content-${dropdown.uniqueId}`);
      element.setAttribute('aria-controls', `ember-basic-dropdown-content-${dropdown.uniqueId}`);
      element.setAttribute('aria-expanded', dropdown.isOpen ? 'true' : 'false');
      element.setAttribute('aria-disabled', dropdown.disabled ? 'true' : 'false');
    }
    handleMouseEvent(e) {
      if (typeof document === 'undefined') return;
      const {
        dropdown,
        desiredEventType,
        stopPropagation
      } = this;
      if ((0, _destroyable.isDestroyed)(this) || !dropdown || dropdown.disabled) return;
      const eventType = e.type;
      const notLeftClick = e.button !== 0;
      if (eventType !== desiredEventType || notLeftClick) return;
      if (stopPropagation) e.stopPropagation();
      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }
      dropdown.actions.toggle(e);
    }
    handleKeyDown(e) {
      const {
        disabled,
        actions
      } = this.dropdown;
      if (disabled) return;
      if (e.keyCode === 13) {
        // Enter
        actions.toggle(e);
      } else if (e.keyCode === 32) {
        // Space
        e.preventDefault(); // prevents the space to trigger a scroll page-next
        actions.toggle(e);
      } else if (e.keyCode === 27) {
        actions.close(e);
      }
    }
    handleTouchStart() {
      document.addEventListener('touchmove', this._touchMoveHandler);
    }
    handleTouchEnd(e) {
      this.toggleIsBeingHandledByTouchEvents = true;
      const {
        disabled,
        actions
      } = this.dropdown;
      if (e && e.defaultPrevented || disabled) {
        return;
      }
      if (!(0, _hasMoved.default)(e, this.touchMoveEvent)) {
        actions.toggle(e);
      }
      this.touchMoveEvent = undefined;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      // This next three lines are stolen from hammertime. This prevents the default
      // behaviour of the touchend, but synthetically trigger a focus and a (delayed) click
      // to simulate natural behaviour.
      const target = e.target;
      if (target !== null) {
        target.focus();
      }
      setTimeout(function () {
        if (!e.target) {
          return;
        }
        try {
          const event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          e.target.dispatchEvent(event);
        } catch (e) {
          const event = new Event('click');
          e.target.dispatchEvent(event);
        }
      }, 0);
      e.preventDefault();
    }
    _touchMoveHandler(e) {
      this.touchMoveEvent = e;
      document.removeEventListener('touchmove', this._touchMoveHandler);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleMouseEvent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchStart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTouchEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_touchMoveHandler", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_touchMoveHandler"), _class.prototype)), _class);
  _exports.default = DropdownTriggerModifier;
  function cleanup(instance) {
    const {
      triggerElement
    } = instance;
    if (triggerElement) {
      if (typeof document !== 'undefined') document.removeEventListener('touchmove', instance._touchMoveHandler);
      triggerElement.removeEventListener('click', instance.handleMouseEvent);
      triggerElement.removeEventListener('mousedown', instance.handleMouseEvent);
      triggerElement.removeEventListener('keydown', instance.handleKeyDown);
      triggerElement.removeEventListener('touchstart', instance.handleTouchStart);
      triggerElement.removeEventListener('touchend', instance.handleTouchEnd);
    }
  }
});